import { VerifiedIcon } from '../icons'
import clsx from 'clsx'
// @ts-ignore
import fallbackAvatarImage from '#/images/fallbackAvatar.webp'

const Avatar = ({
	avatar = { src: fallbackAvatarImage, name: 'Artist Name' },
	size = 'xLarge',
	isVerified = true,
	additionalClasses = '',
	...props
}: {
	avatar: { src: string; name: string }
	size?: 'xxLarge' | 'xLarge' | 'large' | 'medium' | 'small' | 'xSmall'
	isVerified?: boolean
	additionalClasses?: string
}) => {
	const iconSizeMap = {
		xxLarge: 32,
		xLarge: 24,
		large: 16,
		medium: 16,
		small: 12,
		xSmall: 10,
	}

	return (
		<div
			className={clsx('flex justify-center pb-8 md:pb-0', additionalClasses)}
			{...props}
		>
			<span
				className={clsx(
					size === 'xxLarge' && 'ring-offset-2 hover:ring',
					size === 'xLarge' && 'ring-offset-2 hover:ring',
					size === 'large' && 'ring-offset-2 hover:ring-2',
					size === 'medium' && 'ring-offset-2 hover:ring-2',
					size === 'small' && 'ring-offset-1 hover:ring-2',
					size === 'xSmall' && 'ring-offset-1 hover:ring-1',
					`group relative inline-block rounded-full transition-all hover:ring-textPrimaryLight dark:hover:ring-textPrimaryDark`,
				)}
			>
				<img
					className={clsx(
						size === 'xxLarge' && 'h-32 w-32 border-2',
						size === 'xLarge' && 'h-24 w-24 border-2',
						size === 'large' && 'h-16 w-16 border-2',
						size === 'medium' && 'h-12 w-12 border-2',
						size === 'small' && 'border-1 h-10 w-10',
						size === 'xSmall' && 'border-1 h-8 w-8',
						`rounded-full border-textPrimaryDark transition-all dark:border-textPrimaryLight`,
					)}
					src={avatar.src}
					alt={avatar.name}
				/>
				{isVerified && (
					<div
						className={`absolute bottom-0 right-0 block rounded-full bg-white ring-2 ring-white transition-all group-hover:ring-black dark:bg-dark dark:text-white dark:ring-dark group-hover:dark:ring-white`}
					>
						<VerifiedIcon size={iconSizeMap[size]} />
					</div>
				)}
			</span>
		</div>
	)
}

export default Avatar
